import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SCREEN_PATHS } from "../../constants";
import "./styles.scss";
import { PreLoginHeader } from "../Common/Headers/PreLoginHeader";
import { Button, Col, Form, Input, Row, Spin } from "antd";
import { ImageList } from "../../constants/imageList";
import { emailPattern } from "../../utils/formValidations";
import { useDispatch, useSelector } from "react-redux";
import { postForgotPassword1 } from "../../appConfig/redux/reducer/auth.reducer";
import { toaster } from "../../utils/toast";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [value, setValue] = useState();
  const [isOtpSend, setIsOtpSend] = useState(false);
  const onFinish = (values) => {
    if (isOtpSend) {
      if (auth.forgotPasswordData?.otp == values.otp) {
        navigate(SCREEN_PATHS.resetPassword);
      } else {
        toaster("error", "Incorrect OTP");
      }
    } else {
      setValue(values);
      dispatch(
        postForgotPassword1({
          values: { ...values, userType: "re_developer" },
          callback: () => {
            setIsOtpSend(true);
          },
        })
      );
    }
  };
  const onFinishFailed = (values) => {};

  return (
    <div className="forgot-password-container">
      <PreLoginHeader className="login" />
      <Row md className="main-container">
        <Col md={12} className="left-container">
          <img src={ImageList.forgotPasswordImage} />
        </Col>
        {isOtpSend ? (
          <Col md={12} className="right-container">
            <h1>OTP Verification</h1>
            <h5>We've sent you a OTP to your email</h5>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="form-container"
              autoComplete="off"
            >
              <p>OTP</p>
              <Form.Item
                name="otp"
                className="Field"
                rules={[
                  {
                    required: true,
                    message: "Please enter your OTP!",
                  },
                ]}
              >
                <Input placeholder="Enter your OTP here..." type="text" />
              </Form.Item>
              <p className="resend">
                Didn't received your mail?{" "}
                <a
                  onClick={() => {
                    dispatch(
                      postForgotPassword1({
                        values: { ...value, userType: "re_developer" },
                        callback: () => {
                          setIsOtpSend(true);
                        },
                      })
                    );
                  }}
                >
                  Click to resend
                </a>
              </p>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Verify OTP
                </Button>
              </Form.Item>
            </Form>
            <p className="sign-up">
              back to <a href={SCREEN_PATHS.login}>Login</a>
            </p>
          </Col>
        ) : (
          <Col md={12} className="right-container">
            <h1>Forgot Password</h1>
            <h5>
              Don’t worry we can help you out. If you still remember your
              registered email you can quickly reset your password. Just input
              that information in the field blow and hit the button.
            </h5>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="form-container"
              autoComplete="off"
            >
              <p>Email</p>
              <Form.Item
                name="email"
                className="Field"
                rules={[
                  {
                    required: true,
                    pattern: emailPattern.value,
                    message: emailPattern.message,
                  },
                ]}
              >
                <Input placeholder="Enter email address here..." type="text" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {auth?.isLoading ? <Spin /> : "Request OTP"}
                </Button>
              </Form.Item>
            </Form>
            <p className="sign-up">
              back to <a href={SCREEN_PATHS.login}>Login</a>
            </p>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default ForgotPassword;
