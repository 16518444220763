import React from "react";
import "./styles.scss";
import { Row } from "antd";
import img from "../../../../assets/icons/app_icon.png";

export const PreLoginHeader = () => {
  return (
    <div className="main-container">
      <Row justify={"space-between"} align={"middle"}>
        <Row className="logo-container" align={"start"}>
          <div className="logo">
            <img src={img} />
          </div>
          <p className="logo-text">YIMBY</p>
        </Row>
        <div className="options-container">
          <a href="#">Features</a>
          <a href="#">About</a>
          <a
            href={
              window.location.pathname.match("admin-login")
                ? "login"
                : "admin-login"
            }
          >
            {!window.location.pathname.match("admin-login")
              ? "Admin Login"
              : "Developer Login"}
          </a>
          <a href="#">Contact Us</a>
        </div>
      </Row>
    </div>
  );
};
