export const SCREEN_NAMES = {
  welcome: "Welcome",
  home: "Home",
  signup: "Signup",
  login: "Login",
  adminLogin: "Admin Login",
  setNewPassword: "Set New Password",
  forgotPassword: "Forgot Password",
  resetPassword: "Reset Password",
  dashboard: "Dashboard",
  project: "Project",
  card: "Card",
  setting: "Setting",
  userManagement: "User Management",
  userDetail: "User Management - User Detail",
  report: "Report",
  requestList: "Request List",
  message: "Message",
  projectDetail: "Project Detail",
  newProject: "New Project",
  pdf: "PDF",
  editProject: "Edit Project",
  neighbours: "User Management - Neighbours",
  developers: "User Management - Developers",
};

export const SCREEN_PATHS = {
  welcome: "/",
  signup: "/signup",
  login: "/login",
  adminLogin: "/admin-login",
  home: "/home",
  pdf: "/PDF",
  setNewPassword: "/set-new-password",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  dashboard: "/dashboard",
  project: "/project",
  card: "/card",
  setting: "/setting",
  userManagement: "/user-management",
  userDetail: "/user-management/user-detail",
  report: "/report",
  requestList: "/request-list",
  message: "/message",
  projectDetail: "/project-detail",
  newProject: "/new-project",
  editProject: "/edit-project",
  neighbours: "/user-management/neighbours",
  developers: "/user-management/developers",
};
