import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineLock, AiOutlineUser } from "react-icons/ai";
import { SCREEN_PATHS } from "../../constants";
import "./styles.scss";
import { PreLoginHeader } from "../Common/Headers/PreLoginHeader";
import { Button, Checkbox, Col, Form, Input, Row, Spin, Switch } from "antd";
import { ImageList } from "../../constants/imageList";
import { useDispatch, useSelector } from "react-redux";
import {
  postLogin,
  setDeviceToken,
} from "../../appConfig/redux/reducer/auth.reducer";
import { getToken } from "firebase/messaging";
import messaging from "../../firebase";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);
  const setToken = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
        })
          .then((currentToken) => {
            if (currentToken) {
              dispatch(setDeviceToken({ values: { token: currentToken } }));
            } else {
              // Show permission request UI
              console.log(
                "No registration token available. Request permission to generate one."
              );
              // ...
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
            // ...
          });
      }
    });
  };
  const onFinish = (values) => {
    dispatch(
      postLogin({
        values: {
          ...values,
          userType: "re_developer",
        },
        callback: () => {
          navigate(SCREEN_PATHS.project);
          setToken();
        },
      })
    );
  };

  const onFinishFailed = (values) => {};

  return (
    <div className="login-container">
      <PreLoginHeader className="login" />
      <Row md className="main-container">
        <Col md={12} className="left-container">
          <img src={ImageList.loginImage} />
        </Col>
        <Col md={12} className="right-container">
          <h1>Let’s Get started</h1>
          <h5>Welcome back to YIMBY!</h5>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="form-container"
            autoComplete="off"
          >
            <p>Email</p>
            <Form.Item
              name="email"
              className="Field"
              rules={[
                {
                  required: true,
                  message: "Please enter your email!",
                },
              ]}
            >
              <Input
                placeholder="Email"
                type="text"
                style={{ marginBottom: "5px" }}
              />
            </Form.Item>
            <p>Password</p>
            <Form.Item
              name="password"
              className="Field"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                placeholder="Password"
                type="password"
                style={{ marginBottom: "5px" }}
              />
            </Form.Item>

            <Row
              className="remember-me-container"
              justify={"space-between"}
              align={"middle"}
            >
              {/* <Row>
                <Checkbox />
                <p>Remember me</p>
              </Row> */}
              <a href={SCREEN_PATHS.forgotPassword}>Forgot Password</a>
            </Row>
            {/* </Form.Item> */}

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={isLoading}>
                {isLoading ? <Spin /> : "Login"}
              </Button>
            </Form.Item>
          </Form>
          <p className="sign-up">
            Don't have an account yet? <a href={SCREEN_PATHS.signup}>Sign Up</a>
          </p>
        </Col>
      </Row>
    </div>
  );
};
export default Login;
