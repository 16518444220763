import { SCREEN_NAMES, SCREEN_PATHS } from "../../constants";
import Project from "../../screens/Project";
import ForgotPassword from "../../screens/ForgotPassword";
import Login from "../../screens/Login";
import ResetPassword from "../../screens/ResetPassword";
import SetNewPassword from "../../screens/SetNewPassword";
import Signup from "../../screens/Signup";
import Welcome from "../../screens/Welcome";
import ProjectDetail from "../../screens/ProjectDetail";
import NewProject from "../../screens/NewProject";
import Card from "../../screens/Card";
import Setting from "../../screens/Setting";
import EditProject from "../../screens/EditProject";
import UserManagement from "../../screens/UserManagement";
import RequestList from "../../screens/RequestList";
import NeighbourManagement from "../../screens/UserManagement/Neighbour";
import DeveloperManagement from "../../screens/UserManagement/Developer";
import UserDetail from "../../screens/UserManagement/UserDetail";
import Report from "../../screens/Report";
import Dashboard from "../../screens/Dashboard";
import Message from "../../screens/Message";
import AdminLogin from "../../screens/AdminLogin";
export const ROUTES = [
  {
    key: SCREEN_NAMES.login,
    exact: true,
    path: SCREEN_PATHS.login,
    component: Login,
  },
  {
    key: SCREEN_NAMES.adminLogin,
    exact: true,
    path: SCREEN_PATHS.adminLogin,
    component: AdminLogin,
  },
  {
    key: SCREEN_NAMES.signup,
    exact: true,
    path: SCREEN_PATHS.signup,
    component: Signup,
  },
  {
    key: SCREEN_NAMES.welcome,
    exact: true,
    path: SCREEN_PATHS.welcome,
    component: Welcome,
  },

  {
    key: SCREEN_NAMES.setNewPassword,
    exact: true,
    path: SCREEN_PATHS.setNewPassword,
    component: SetNewPassword,
  },
  {
    key: SCREEN_NAMES.resetPassword,
    exact: true,
    path: SCREEN_PATHS.resetPassword,
    component: ResetPassword,
  },
  {
    key: SCREEN_NAMES.forgotPassword,
    exact: true,
    path: SCREEN_PATHS.forgotPassword,
    component: ForgotPassword,
  },
];
export const PROTECTED_ROUTES = [
  {
    key: SCREEN_NAMES.project,
    exact: true,
    path: SCREEN_PATHS.project,
    component: Project,
  },
  {
    key: SCREEN_NAMES.dashboard,
    exact: true,
    path: SCREEN_PATHS.dashboard,
    component: Dashboard,
  },

  {
    key: SCREEN_NAMES.projectDetail,
    exact: true,
    path: SCREEN_PATHS.projectDetail,
    component: ProjectDetail,
  },
  {
    key: SCREEN_NAMES.newProject,
    exact: true,
    path: SCREEN_PATHS.newProject,
    component: NewProject,
  },
  {
    key: SCREEN_NAMES.editProject,
    exact: true,
    path: SCREEN_PATHS.editProject,
    component: EditProject,
  },
  {
    key: SCREEN_NAMES.card,
    exact: true,
    path: SCREEN_PATHS.card,
    component: Card,
  },
  {
    key: SCREEN_NAMES.setting,
    exact: true,
    path: SCREEN_PATHS.setting,
    component: Setting,
  },
  {
    key: SCREEN_NAMES.userManagement,
    exact: true,
    path: SCREEN_PATHS.userManagement,
    component: UserManagement,
  },
  {
    key: SCREEN_NAMES.requestList,
    exact: true,
    path: SCREEN_PATHS.requestList,
    component: RequestList,
  },
  {
    key: SCREEN_NAMES.neighbours,
    exact: true,
    path: SCREEN_PATHS.neighbours,
    component: NeighbourManagement,
  },
  {
    key: SCREEN_NAMES.developers,
    exact: true,
    path: SCREEN_PATHS.developers,
    component: DeveloperManagement,
  },
  {
    key: SCREEN_NAMES.userDetail,
    exact: true,
    path: SCREEN_PATHS.userDetail,
    component: UserDetail,
  },
  {
    key: SCREEN_NAMES.report,
    exact: true,
    path: SCREEN_PATHS.report,
    component: Report,
  },
  {
    key: SCREEN_NAMES.message,
    exact: true,
    path: SCREEN_PATHS.message,
    component: Message,
  },
];
